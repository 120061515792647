<template>
  <div class="add_page_wrap" >
    <div class="form_item" >
      <div class="form_item_label" >收货人：</div>
      <input class="form_input" placeholder="请输入收货人" v-model="form.realName" />
    </div>
    <div class="form_item" >
      <div class="form_item_label" >手机号码：</div>
      <input class="form_input" placeholder="请输入手机号码" v-model="form.phone" />
    </div>
    <div class="form_item" @click="show = true" >
      <div class="form_item_label" >所在地区：</div>
      <input class="form_input" placeholder="请选择所在地区" readonly v-model="areaName" />
    </div>
    <div class="form_item textarea_input" >
      <div class="form_item_label" >详细地址：</div>
      <textarea class="form_input" placeholder="请输入详细地址" v-model="form.detail" />
    </div>
    <div class="space" ></div>
    <div class="form_item" >
      <div class="form_item_label" >默认地址：</div>
      <van-switch v-model="checked" size="20" active-color="#03CFAF" />
    </div>
    <van-popup v-model="show" position="bottom">
      <van-area title="所在地区" :area-list="areaList" @confirm="areaConfirm" @cancel="show = false" />
    </van-popup>
    <van-button class="confirm_button" :loading="submitting" @click="handleCommit" >{{ userAddressId ? '修改地址' : '确定' }}</van-button>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  addUserAddress,
  getUserAddressVo,
  updateUserAddress
} from '@/api'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant'
export default {
  name: 'addAddress',
  data() {
    return {
      checked: false,
      areaName: '',
      form: {
        realName: '',
        phone: '',
        detail: ''
      },
      userAddressId: this.$route.query.userAddressId,
      submitting: false,
      show: false,
      areaList
    }
  },
  methods: {
    handleCommit() {
      const { userAddressId, form, checked } = this
      if(!form.realName) {
        Toast('请输入收货人')
        return false
      }
      if(!form.phone) {
        Toast('请输入手机号码')
        return false
      }
      if(!form.province) {
        Toast('请选择所在地区')
        return false
      }
      if(!form.province) {
        Toast('请选择所在地区')
        return false
      }
      if(!form.detail) {
        Toast('请输入详细地址')
        return false
      }
      form.isDefault = !this.checked ? 0 : 1
      if(!this.userAddressId) {
        this.add(form)
      }else {
        this.update(form)
      }
    },
    add(values) {
      this.submitting = true
      addUserAddress({...values}).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('地址添加成功')
          this.$router.go(-1)
        }else {
          Toast(res.msg)
        }
      })
    },
    update(values) {
      this.submitting = true
      values.userAddressId = this.userAddressId
      updateUserAddress({...values}).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('地址修改成功')
          this.$router.go(-1)
        }else {
          Toast(res.msg)
        }
      })
    },
    areaConfirm(event) {
      console.log(event)
      this.form.province = event[0].name
      this.form.city = event[1].name
      this.form.district = event[2].name
      this.areaName = event[0].name + event[1].name + event[2].name
      this.show = false
    },
    getDetail() {
      const { userAddressId } = this
      getUserAddressVo({ userAddressId }).then(res => {
        console.log(res)
        if(res.success) {
          let data = res.data
          console.log({...data})
          this.form = {
            ...data
          }
          this.checked = data.isDefault == 1
          this.areaName = data.province + data.city + data.district
        }
      })
    }
  },
  mounted() {
    if(this.userAddressId) {
      document.title = '修改地址'
      this.getDetail()
    }else {
      document.title = '新增地址'
    }
  }
}
</script>
